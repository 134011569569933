import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { primaryLight } from '../theme/AppThemes'
import { ThemeProvider } from '@material-ui/core/styles'
import { CircularProgress, InputAdornment, Typography } from '@material-ui/core'
import Axios from 'axios'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { StripeCheckoutAPI } from './strip-checkout/Checkout'
import moment from 'moment'

interface Props {
  price: string // stripe price id
  deposit: string
  isOpen: boolean
  handleClose: () => void
}

interface BookingRequest {
  location: string
  date: string | number
  time: string | number
  formattedMomentTime: string
  instagram: string
}

export const FormModal: React.FC<Props> = ({
  isOpen,
  price,
  deposit,
  handleClose,
}) => {
  const [bookingInfo, updateBookingInfo] = React.useState<BookingRequest>({
    location: '',
    date: Date.now(),
    time: Date.now(),
    formattedMomentTime: '',
    instagram: '',
  })

  const [isAwaitingSubmit, updateIsAwaitingSubmit] = React.useState(false)

  React.useEffect(() => {
    console.log('price', price)
  }, [price])

  React.useEffect(() => {
    console.log('deposit', deposit)
  }, [deposit])

  React.useEffect(() => {
    console.log('bookingInfo', bookingInfo)
  }, [bookingInfo])

  const { GATSBY_EMAIL_HOST } = process.env

  const handleDateChange = (date: Date | null, value: any) => {
    console.log('date', date)
    console.log('value', value)
    updateBookingInfo({
      ...bookingInfo,
      date: value,
    })
  }
  const handleTimeChange = (date: string, value: any) => {
    console.log('date', date)
    console.log('time moment', moment(date).format('LT'))
    console.log('value', value)
    updateBookingInfo({
      ...bookingInfo,
      time: date,
      formattedMomentTime: moment(date).format('LT'),
    })
  }

  const handleSubmit = async () => {
    try {
      console.log('bookingInfo', bookingInfo)
      console.log('GATSBY_EMAIL_HOST', GATSBY_EMAIL_HOST)
      console.log('price', price)
      updateIsAwaitingSubmit(true)
      let response = await Axios.post(
        GATSBY_EMAIL_HOST,
        // 'http://localhost:6000/api/wsh-ent/email',
        // 'https://cors-anywhere.herokuapp.com/https://clarks-construction-backend.herokuapp.com/api/wsh-ent/email',
        bookingInfo
      )
      console.log('response from email', response)

      // call stripe
      const res = await StripeCheckoutAPI(
        [{ price, quantity: 1 }],
        'booking',
        'booking'
      )
      console.log('res from StripeCheckoutAPI', res)

      updateBookingInfo({
        location: '',
        date: '',
        time: '',
        instagram: '',
      })
      updateIsAwaitingSubmit(false)
      handleClose()
      return res
    } catch (e) {
      toast.error('Error with Booking Request. Please try again.')
      console.log('error', e)
      updateIsAwaitingSubmit(false)
      return e
    }
  }

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target
    updateBookingInfo({
      ...bookingInfo,
      [id]: value,
    })
  }

  const isDisabled =
    isAwaitingSubmit ||
    bookingInfo.location === '' ||
    bookingInfo.time === '' ||
    bookingInfo.date === ''

  return (
    <ThemeProvider theme={primaryLight}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog
        open={isOpen}
        aria-labelledby="form-dialog-title"
        style={{ textAlign: 'center' }}
      >
        <DialogTitle>Book Session </DialogTitle>

        <Typography variant={'h3'}>Deposit Amount: {deposit}</Typography>

        <Typography display={'block'} variant={'h3'}>
          Deposit goes toward the total price.
        </Typography>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoFocus
              disableToolbar
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date"
              label="Date"
              value={bookingInfo.date}
              onChange={handleDateChange}
              type={'standard-date'}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              fullWidth
              margin="normal"
              id="time"
              label="Time"
              value={bookingInfo.time}
              onChange={handleTimeChange}
              type={'standard-date'}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            fullWidth
            required
            placeholder={'Location'}
            value={bookingInfo.location}
            onChange={handleOnChangeInput}
            margin={'dense'}
            id={'location'}
            label={'Location'}
            type={'standard-required'}
            helperText={
              'Final Price may vary if location is outside the Atlanta Area'
            }
          />
          <TextField
            fullWidth
            value={bookingInfo.instagram}
            onChange={handleOnChangeInput}
            margin={'dense'}
            id={'instagram'}
            label={'Instagram'}
            type={'standard'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} onClick={handleClose} color={'default'}>
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={handleSubmit}
            variant={'contained'}
            color="primary"
          >
            {isAwaitingSubmit && (
              <CircularProgress
                color={'primary'}
                size={24}
                style={{ marginRight: '8px' }}
              />
            )}
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}
