import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/layout'
import Loadable from 'react-loadable'
import SEO from '../components/seo'
import {
  Typography,
  CardHeader,
  CardContent,
  Card,
  Container,
  Grid,
  Divider,
} from '@material-ui/core'
import Pricing from '../components/Pricing'
// import Calendar from 'react-calendar'
// import 'react-calendar/dist/Calendar.css'
// require('react-calendar/dist/Calendar.css')

const Loading = () => <Typography variant={'caption'}>...loading</Typography>

const LoadableCalendar = Loadable({
  loader: () => import('../components/calendar/Calendar.container'),
  loading: Loading,
})

interface Props {
  data: any
}

const Contact: React.FC<Props> = ({ data }) => {
  const { edges } = data.allContentfulCalendar

  return (
    <Layout>
      <SEO
        title="booking"
        keywords={[
          `booking`,
          `wsh entertainment`,
          `videography`,
          `photography`,
        ]}
      />
      <Container>
        <Grid container style={{ margin: '50px 0px' }}>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h1"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Pricing
            </Typography>
            <Typography
              variant="h3"
              align="center"
              color="textSecondary"
              component="p"
              gutterBottom
            >
              Film Production Packages
            </Typography>
            <Pricing />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Typography variant={'h1'} style={{ margin: '10px 0px' }}>
                    Availability
                  </Typography>
                }
                subheader={'Time slots in Red are Unavailable'}
                subheaderTypographyProps={{
                  variant: 'h3',
                  // style: {
                  //   fontSize: '32px',
                  // },
                }}
              />
              <CardContent>
                <Divider style={{ marginBottom: '10px' }} />
                <LoadableCalendar events={edges} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query CalendarQuery {
    allContentfulCalendar {
      edges {
        node {
          start
          title
        }
      }
    }
  }
`
