import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import StarIcon from '@material-ui/icons/StarBorder'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { primaryLight } from '../theme/AppThemes'
import { FormModal } from './FormModal'

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'baseline',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}))

const tiers = [
  {
    title: 'Basic 4K',
    price: '400',
    // TEST PRICE ID
    // id: 'price_1HQjhTBMswRoKviSCK0hpHvp',
    id: 'price_1HdHX1BMswRoKviSIVZVoRsD',
    deposit: '$100',
    description: [
      '1 Location',
      '3 Hour Shoot',
      'Editing Services',
      'Digital File Transfer',
    ],
    buttonText: 'Book Now',
    buttonVariant: 'outlined',
  },
  {
    title: 'Deluxe 4K',
    subheader: 'Most popular',
    price: '600',
    // TEST PRICE ID
    // id: 'price_1HQjiABMswRoKviSDDNjSh5J',
    id: 'price_1HdHWvBMswRoKviS4fqmCCXT',
    deposit: '$200',
    description: [
      'Up to 2 Locations',
      '6 Hour Shoot',
      'Drone Footage',
      'Editing Services',
      'Digital File Transfer',
      '$200 Deposit',
    ],
    buttonText: 'Book Now',
    buttonVariant: 'outlined',
  },
  {
    title: 'Supreme 4K',
    price: '800',
    // TEST PRICE ID
    // id: 'price_1HQjirBMswRoKviSegrhippk',
    id: 'price_1HdHVeBMswRoKviSmgPeFbyK',
    deposit: '$300',
    description: [
      'Up to 3 Locations',
      'Behind the Scenes shoot',
      'Cover Art for the single',
      'Drone Footage',
      'Editing Services',
      'Digital File Transfer',
      '$300 Deposit',
    ],
    buttonText: 'Book Now',
    buttonVariant: 'outlined',
  },
]

export default function Pricing() {
  const classes = useStyles()
  const [isOpen, updateOpen] = React.useState(false)
  const [price, updatePrice] = React.useState('')
  const [deposit, updateDeposit] = React.useState('')

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    id: string,
    depositAmount: string
  ) => {
    updatePrice(id)
    updateDeposit(depositAmount)
    updateOpen(true)
  }

  const handleModalClose = () => updateOpen(false)

  return (
    <ThemeProvider theme={primaryLight}>
      <CssBaseline />
      {/* Hero unit */}
      <Card
        maxWidth="sm"
        component="main"
        className={classes.heroContent}
        style={{ margin: '50px 0px' }}
      >
        {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier: any, i: number) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === 'Premiere 4K' ? 12 : 6}
                md={4}
                // style={{ height: i === 0 || i === 2 ? '500px' : '600px' }}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{
                      align: 'center',
                      variant: 'h1',
                      style: {
                        fontSize: '30px',
                      },
                    }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography display={'block'} component="h2" variant="h3">
                        ${tier.price}
                      </Typography>
                      <Typography
                        display={'block'}
                        variant="h4"
                        color="textSecondary"
                      >
                        {i === 0
                          ? '$100 deposit'
                          : i === 1
                          ? '$200 deposit'
                          : '$300 deposit'}
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map(line => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      id={tier.id}
                      onClick={e => handleOnClick(e, tier.id, tier.deposit)}
                      variant={tier.buttonVariant}
                      color="primary"
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Card>
      <FormModal
        deposit={deposit}
        price={price}
        isOpen={isOpen}
        handleClose={handleModalClose}
      />
    </ThemeProvider>
  )
}
